.body{
	font-family:oxygen;
	height: auto;
}
.header{
	height:45px;
	background-color:dodgerblue;
	margin-bottom:10px;
	padding-right:2px;
}
.header li{
	list-style: none;
	margin: 1%;
	
}
.link{
	text-decoration: none;
	font-weight: bold;
}

.logo{
	margin-left: 40px;
	display:inline-block;
	border-color:dodgerblue;
}
.homeLogin{
	position: relative;
	top: -70px;
	display: flex;
	justify-content: flex-end;
}
.home{
	margin: 4px 39px 19px 0px;
	text-decoration: none;
	font-weight: bold;
}
.login{
	margin: 4px 59px 19px 0px;
	text-decoration: none;
	font-weight: bold;
}
.logout{
	cursor: pointer;
}

.nav{
	margin:30;
}
.button{
	background-color:dodgerblue;
	padding-right:2px;
}

.forfont{
	font-size:22px;
}

.footer{
	background-color:dodgerblue;
	display:block;
	/* bottom: -1.5%; */
	height:40px;
	position: fixed;
	/* left: 0; */
	bottom: 0;
	width: 100%;
}

.wordcount{
	font-size:10px;	
	margin:10px 10px;
}
.fontofdocinfo{
	font-size:15px;
}
.inputboxcolor{
	background-color:rgb(255,255,153);
	width: 65%;
	position: relative;
	top: -8px;
}

.shadow{
	height: auto;
	padding-left: 10%;
	border-radius: 50px;
	margin-left: 25%;
	width: 40%;
	border: 1px solid;
	box-shadow: 5px 10px 8px #888888;
}
.count{
	width: 30%;
}

.paymentBtn{
	width: 70%;
}

.googleLogout{
	margin-top: -7px;
	width: 120px;
	height: 2.7rem;
	background-color: dodgerblue;
}