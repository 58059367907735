.block{
	padding-left: 10%;
	border-radius: 50px;
	margin-left: 32%;
    margin-top: 6%;
    padding-top: -5%;
    padding-bottom: 5%;
	width: 25%;
    height: 40%;
	border: 1px solid;
    background-color: #ccbfbf57;
	box-shadow: 5px 10px 8px #888888;
}

form input , button{
    width: 60%;
    height: 25px;
    background-color: rgb(221, 224, 224);
    border: 1px solid rgb(80, 133, 133);
    margin: 2%;
}

form span{
    color: red;
    font-size: 0.7rem;
    margin-left: 15px;
}

.form{
    margin-left: -15%;
}

.g-Login{
    height: 50%;
    width: 50%;
    margin-Left: -13%;
}

.l-signin{
    height: 50%;
    width: 72%;
}

.third-party{
    height: auto;
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

